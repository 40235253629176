/**
 * This utility class permits the abstraction of methods
 * for reuse
 */
import imagemapjson from "../static/imagemap.json";

const imagemap = new Map(Object.entries(imagemapjson));

/**
   *
   * @param {The type of the enum - like GENDER or PARTY} enumType 
   * @param {The Key to the enum you are looking-up} entryKey
   * @returns The usable path and filename of the image found or <undefined>
   */
export default function getCharacteristicsImagePath(enumType,entryKey) {
    var useablePath ;
    try {
      const mapOfCharacteristics = new Map(
        Object.entries(imagemap.get(enumType))
      );
      const relativePath = mapOfCharacteristics.get(entryKey);
      useablePath = process.env.PUBLIC_URL + relativePath;
      //console.log(`IMAGE PATH:,${useablePath} entry ${entryKey} enumtype ${enumType}`);
    } catch {}
    return useablePath;
  }

  /**
   * 
   * @param {The type of the enum - like GENDER or PARTY} enumType 
   * @param {The Key to the enum you are looking-up} entryKey
   * @returns Boolean
   */
  export function imagePathValid(enumType,entryKey){
    const path = getCharacteristicsImagePath(enumType,entryKey);
    const b  = path !== 'undefined';
    return b;
  }

  /**
   * 
   * @param {A url String} url 
   * @returns Boolean 
   * if url is valid construct but does not check existance
   * //console.log ("VALID",validUrl("bibble"));
   */
  export function validUrl(url) {
    return /http(s)?:\/\/(\w+:?\w*@)?(\S+)(:\d+)?((?<=\.)\w+)+(\/([\w#!:.?+=&%@!\-/])*)?/gi.test(url);
  }
  
  