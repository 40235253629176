import * as React from "react";


import CopyrightIcon from '@mui/icons-material/Copyright';
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';

export default function SimpleBottomNavigation() {
  

  return (
    <Paper
      sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      elevation={3}
    >
      
       <Table>
        <TableBody>
        <TableRow>
            <TableCell align="center">
            <CopyrightIcon/>
            <Typography sx={{ fontSize: 6 }}>Copyright Liberty Communications Limited 2023</Typography>
            </TableCell>
        </TableRow>
        </TableBody>
       </Table>
      
    </Paper>
  );
}
