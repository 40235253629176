
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

import { View } from "@aws-amplify/ui-react";
import IconButton from "@mui/material/IconButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import Tooltip from "@mui/material/Tooltip";
import {
  Typography,
  Modal,
  Box,
  Grid,
  Menu,
  MenuItem,
  Fade,
} from "@mui/material";
import { BrowserRouter, Routes, Route, redirect } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { CognitoUserContext } from "../contexts/cognitousercontext.js";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// So here we can grab the url and read in the details for the sub_id
// We can then make a call to cognito to get the latest attributes presumbly.

const searchString = window.location.search;
//console.log("WLS ", searchString);
const params = new URLSearchParams(searchString);


const sub_id = params.get("sub_id");
const session_id = params.get("session_id");

function Success() {
    const { cognitoUser, setCognitoUser } =
    useContext(CognitoUserContext);
  const [modalOpen, setModalOpen] = useState(true);
  const nav = useNavigate();
  // The call below should somehow reload the cognitoUser Attributes
  const handleModalClose = () => {
    setModalOpen(false);
    console.log("Navingating home...");
    loader();
    // Does the nav but nothing in the home triggers any events
    nav("/");
  };
  const loader = async () => {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
    setCognitoUser(user); //looping!!!!!
      return redirect("/");
    
  };
  
  return (
    <div>
      <Modal open={modalOpen}>
        
        <Box>
          <Box sx={style}>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              We're sorry you didn't complete your subscription
            </Typography>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Everything will remain a little blurred
            </Typography>
            

            <Tooltip title="Subscription not complete...">
              <IconButton sx={{ color: "red" }} onClick={handleModalClose}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <SubscriptionsIcon />
        </Box>
      </Modal>
    </div>
  );
}
export default Success;
