import { API } from "@aws-amplify/api";


const stripeAPI = "stripeuserapi";
const capePath = "/stripecancelatendofperiod";


export async function setCancelAtEndOfPeriod(status, subscriptionid) {
    try {
      const items = await API.put(stripeAPI, capePath, {
        queryStringParameters: {
          cancelatendofperiod: status,
          subscriptionid: subscriptionid,
        },
      });
      console.log("CAPE IS", items);
      console.log("put call succeeded");
    } catch (error) {
      console.log("GET call failed: ", JSON.parse(error.response.body));
    }
  }