import { useContext } from "react";
import * as React from "react";
import { View } from "@aws-amplify/ui-react";
import IconButton from "@mui/material/IconButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Amplify, Auth } from "aws-amplify";


const AUTHENTICATEDUSER_USER = "AUSER";
window.localStorage.getItem = window.localStorage.getItem.bind(
  window.localStorage
);
window.localStorage.setItem = window.localStorage.setItem.bind(
  window.localStorage
);

async function currentAuthenticatedUser() {
    const existingConfig = Amplify.Auth.getConfig();
    console.log("ex Con ", existingConfig);
  try {
    
    const { username, userId, signInDetails } = await Auth.currentSession();
    console.log(`The username: ${username}`);
    console.log(`The userId: ${userId}`);
    console.log(`The signInDetails: ${signInDetails}`);
  } catch (err) {
    console.log('error in currentAuthenticatedUser', err);
  }
}
export function setAuthenticatedUser(user) {
  //  window.localStorage.setItem(AUTHENTICATEDUSER_USER,user);
  const a = getAuthenticatedUser();
  window.localStorage.setItem(AUTHENTICATEDUSER_USER, a);
}

export function removeAuthenticatedUser() {
  window.localStorage.setItem(AUTHENTICATEDUSER_USER, null);
}

export function getAuthenticatedUser() {
  return currentAuthenticatedUser();
}
const getAuthedUser = async () => {
  return await new Promise((resolve, reject) => {
    const user = Auth.currentAuthenticatedUser();
    
    if (user) {
      return user;
    } else {
      reject();
    }
  });
};

export function AuthenticatedUser() {
  const user = getAuthenticatedUser();
  try {
    const a = Auth.currentAuthenticatedUser();
    console.log("a...", a);
  } catch (error) {
    console.log("No authenticated user");
  }
  
  function getAuthenticatedUser() {
    const str = window.localStorage.getItem(AUTHENTICATEDUSER_USER);

    if (str === null || str === undefined) {
      return "NONE";
    }
    return str;
  }

  return <div>hello {user}</div>;
}
