import { Auth } from "aws-amplify";
import { redirect } from "react-router-dom";
import * as React from "react";
import { useContext } from "react";
// Note this is client-side stripe API, so you cant call things like stripe.customers.retrieve etc
import { MenuItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import {
  getCognitoUser,
  STRIPE_SUB_STATUS,
  STRIPE_SUBSCR_ID,
} from "../components/util/CognitoUserUtils.js";
import { setCancelAtEndOfPeriod } from "../components/util/StripeApiUtils.js";
import { CognitoUserContext } from "../contexts/cognitousercontext.js";

const getAuthSessionPayload = async () => {
  try {
    const session = await Auth.currentSession();
    console.log(session);
    if (session.idToken.payload) {
      console.log("I HAVE A FUCKING PAYLOAD");
      return session.idToken.payload;
    }
  } catch (error) {
    console.log(error);
  }
};



export default function ReactivateSubscription({ visible }) {
  const { cognitoUser, setCognitoUser } = useContext(CognitoUserContext);
  let display = visible ? "inline" : "none";
  // Handler for the button click event
  async function handleClick(e) {
    
    const authPayload = await getAuthSessionPayload();
    const cognitoSub = authPayload.sub;
    console.log("CurrentUser is:" + cognitoSub);
    const cuname = authPayload["cognito:username"];
    console.log("User name is:" + cuname);
    const r = await getCognitoUser(cuname);
    var cu = r.user;
    const subscriptionId= cognitoUser.attributes[STRIPE_SUBSCR_ID];
    setCancelAtEndOfPeriod(false, subscriptionId);
    loader();
    display= 'none';
    redirect("/");
  }

  const loader = async () => {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
    user.attributes[STRIPE_SUB_STATUS]= "ACTIVE";
    setCognitoUser(user); //looping!!!!!
    display = 'none';
    console.log ("Afte cancel: ",user);
  };

  return (
    <MenuItem
      onClick={handleClick}
      sx={{ display: display, color:'red' }}
      variant="contained"
    >
      <Tooltip title="Reactivate your subscribtion">
        <SubscriptionsIcon />
      </Tooltip>
    </MenuItem>
  );
}
