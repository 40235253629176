
// Note this is client-side stripe API, so you cant call things like stripe.customers.retrieve etc
import { MenuItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";

import { useContext } from "react";
import { CognitoUserContext } from "../contexts/cognitousercontext.js";
import { userIsAdministrator } from "../components/util/CognitoUserUtils.js";
import personsToImport from "../forimport/patten.json";
//import personsToImport from "../forimport/person.json";
import { API } from "aws-amplify";

export default function SlugAdmin({ visible }) {
  const { cognitoUser } = useContext(CognitoUserContext);
  const isAdmin = userIsAdministrator(cognitoUser);
  let display = isAdmin ? "inline" : "none";
  async function handleClick(e) {
    console.log("IMP:", JSON.stringify(personsToImport));
 //   loader();
    return;
  }

  const loader = async () => {
    const myAPI = "addslugpersonapi";
    const path = "/addperson";
    console.log(`Importing: ${personsToImport.length}`);

    const addPeople = () => {
      (async function () {
        var count =1;
        for (const person of personsToImport) {
          var personArray = [];
          personArray.push(person);
          const res = await API.put(myAPI, path, {
            queryStringParameters: {
              person: JSON.stringify(personArray),
            },
          });
          console.log(`${count} Added: ${JSON.stringify(person)} with result: ${res}`);
          count = count+1;
          console.log(new Date());
        }
      })();
    };
    addPeople();
  };

  

  return (
    <MenuItem disabled={true}
      sx={{ display: display }}
      onClick={handleClick}
      variant="contained"
    >
      <Tooltip title="CLICK TO IMPORT">
        <UnsubscribeIcon />
      </Tooltip>
    </MenuItem>
  );
}
