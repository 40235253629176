import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { BrowserRouter, Routes, Route, redirect } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { View } from "@aws-amplify/ui-react";
import IconButton from "@mui/material/IconButton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import Tooltip from "@mui/material/Tooltip";
import {
  Typography,
  Modal,
  Box,
  Grid,
  Menu,
  MenuItem,
  Fade,
} from "@mui/material";
import { CognitoUserContext } from "../contexts/cognitousercontext.js";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// So here we can grab the url and read in the details for the sub_id
// We can then make a call to cognito to get the latest attributes presumbly.

// HACK
// This is a hack to avoid upsetting auth
const USERPOOL_ID = process.env.AUTH_GAMMA6070A913_USERPOOLID;
// HACK END

const searchString = window.location.search;
//console.log("WLS ", searchString);
const params = new URLSearchParams(searchString);

const sub_id = params.get("sub_id");
const session_id = params.get("session_id");
var username = "blank";
console.log("PARAMS: ", params);
function Success() {
  const { cognitoUser, setCognitoUser } = useContext(CognitoUserContext);
  const [modalOpen, setModalOpen] = useState(true);
  const nav = useNavigate();
  // The call below should somehow reload the cognitoUser Attributes
  const handleModalClose = () => {
    setModalOpen(false);
    username = cognitoUser.username;
    console.log("Navingating home...", username);

    loader();
    // Does the nav but nothing in the home triggers any events
    nav("/");
  };

  // HACK
  const updateuser = async () => {
    var expiryDate = `${new Date().getTime() + 2629746000}`; // Hack for one month
    await Auth.updateUserAttributes(
      {
        UserAttributes: [
          /* required */
          {
            Name: "custom:stripeSubExpiry" /* required */,
            Value: expiryDate,
          },
        ],
        UserPoolId: USERPOOL_ID /* required */,
        Username: sub_id /* required */,
      },
      function (err, data) {
        console.log("In UserUpdate fn");
        console.log(data);
        if (err) console.log(err, err.stack);
        else {
          console.log("User updated..." + sub_id);
          console.log(data);
          //res.sendStatus(200);
        }
      }
    );
  };

  // HACK END
  const loader = async () => {
    // updateuser();
    const session = await Auth.currentSession();
    console.log("SESS: ", session);
    console.log("SESS SUB: ", session.accessToken.payload.sub);
    console.log("session_id:", session_id);
    if (sub_id === session.accessToken.payload.sub) {
      
      var user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      /*
      expiryDate = `${new Date().getTime() + 2629746000}`; // Hack for one month
      await Auth.updateUserAttributes(user, {
        "custom:stripeSubExpiry": expiryDate,
      });
      user = await Auth.currentAuthenticatedUser({ bypassCache: true });
      console.log("USER: ", user);
      */
      setCognitoUser(user); //looping!!!!!
      // Here we could get the sub, sessionid and pssibly expiry dates,compare them
      // to the response (params) and if they match, set the expiry date and save it back
      console.log(
        "******** Just set cognitouser in async loader to ",
        username,
        sub_id,
        session_id
      );
    }
    return redirect("/");
  };

  return (
    <div>
      <Modal  open={modalOpen}>
        <Box>
          <Box sx={style}>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Congratulations, You've joined us!
            </Typography>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Go find some politicians...
            </Typography>

            <Tooltip title="Collect Some People...">
              <IconButton sx={{ color: "red" }} onClick={handleModalClose}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <SubscriptionsIcon />
        </Box>
      </Modal>
    </div>
  );
}
export default Success;
