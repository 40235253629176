import { Auth } from "aws-amplify";
// Note this is client-side stripe API, so you cant call things like stripe.customers.retrieve etc
import { MenuItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";

import { redirect } from "react-router-dom";
import {useContext } from "react";
import { CognitoUserContext } from "../contexts/cognitousercontext.js";
import {setCancelAtEndOfPeriod} from "../components/util/StripeApiUtils.js";
import {
  STRIPE_SUB_STATUS,
} from "../components/util/CognitoUserUtils.js";


const getAuthSessionPayload = async () => {
  try {
    const session = await Auth.currentSession();
    console.log(session);
    if (session.idToken.payload) {
      console.log("I HAVE A FUCKING PAYLOAD");
      return session.idToken.payload;
    }
  } catch (error) {
    console.log(error);
  }
};



export default function PauseSubscription({ visible }) {
  const {  setCognitoUser } =
    useContext(CognitoUserContext);
  let display = visible ? "inline" : "none";
  const state = visible; // Remember the call is to set cancelatendofperiod, so to cancel it, set true
  // Handler for the button click event
  async function handleClick(e) {
    const authPayload = await getAuthSessionPayload();
    const cognitoSub = authPayload.sub;
    console.log("CurrentUser is:" + cognitoSub);
    const cuname = authPayload["cognito:username"];
    console.log("User name is:" + cuname);
    const subscriptionId = authPayload["custom:subscriptionId"];
    const resp = await setCancelAtEndOfPeriod(true, subscriptionId);
    console.log(`resp is ${resp}`);
    loader();
    return redirect("/");
  }

  const loader = async () => {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true })
    user.attributes[STRIPE_SUB_STATUS]= "INACTIVE";
    setCognitoUser(user); //looping!!!!!
    display = 'none';
    console.log ("Afte cancel: ",user);
  };
  
  

  return (
    <MenuItem
      sx={{ display: display }}
      onClick={handleClick}
      // this logic below is not correct for new accounts
      //disabled={NotEligibleToSubscribe()}
      variant="contained"
    >
      <Tooltip title="Pause your subscription renewal">
        <UnsubscribeIcon />
      </Tooltip>
    </MenuItem>
  );
}
