/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "addslugpersonapi",
            "endpoint": "https://t8tncaad62.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        },
        {
            "name": "gammawebhook",
            "endpoint": "https://qsfvzgdk59.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        },
        {
            "name": "sluguserapi",
            "endpoint": "https://vy41k2hvdl.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        },
        {
            "name": "stripeuserapi",
            "endpoint": "https://u2xc79wsh7.execute-api.eu-west-2.amazonaws.com/staging",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://26sbm2c5b5dpzdtf3cy3ha2w5e.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-a5qnww756nfavc7bzyda55hgiy",
    "aws_cognito_identity_pool_id": "eu-west-2:3cfbbb52-6099-467d-abaa-63b8bafa9c9e",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_HLNn7LJps",
    "aws_user_pools_web_client_id": "2cb5hu6891h6duj1anshjtnp8s",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "scratch-staging",
            "region": "eu-west-2"
        }
    ]
};


export default awsmobile;
