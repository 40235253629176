import { Auth } from "aws-amplify";
import { BrowserRouter, Routes, Route, redirect } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
// Note this is client-side stripe API, so you cant call things like stripe.customers.retrieve etc
import { MenuItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import { userSubscriptionRenewalPaused } from "../components/util/UserStatusUtil";
import {getCognitoUser,cognitoUserAttributesToMap,STRIPE_SUB_STATUS,STRIPE_SUBSCR_ID} from "../components/util/CognitoUserUtils";
import {setCancelAtEndOfPeriod} from "../components/util/StripeApiUtils.js";
import { CognitoUserContext } from "../contexts/cognitousercontext.js";
import { useContext } from "react";

const getAuthSessionPayload = async () => {
  try {
    const session = await Auth.currentSession();
    console.log(session);
    if (session.idToken.payload) {
      console.log("I HAVE A FUCKING PAYLOAD");
      return session.idToken.payload;
    }
  } catch (error) {
    console.log(error);
  }
};

function getHostnameWithProtocol() {
  return window.location.origin;
}



export default function Subscribe({ visible }) {
  const { cognitoUser, setCognitoUser } = useContext(CognitoUserContext);
  // This is the API public key from the test environment
  //const stripePublicKeyTest =
  //  "pk_test_51OrIaqCDSeAskprcutcoD3OsHpZcdttjolDK5WZMDDV1eG9JIlcRiihu31aECXgB5Y7m4z0KES7YuvfyzWxaoSuX00zA07XIdL";
  const stripePublicKeyLive="pk_live_51OrIaqCDSeAskprc9oT3KHvJE5oWNU2RNKiXTZoh8rVu411U5mxlpC03BuqP9bP2oDRwhRGBHadjeGklmhU62ys600akGkydj8"
  const display = visible ? "inline" : "none";
  // Handler for the button click event
  async function handleClick(e) {
    const authPayload = await getAuthSessionPayload();
    const cognitoSub = authPayload.sub;
    console.log("CurrentUser is:" + cognitoSub);
    const cuname = authPayload["cognito:username"];
    console.log("User name is:" + cuname);
    const authPayloadEmail = authPayload.email;
    const clientReferenceId = authPayload.sub;
    const stripe = await loadStripe(stripePublicKeyLive); // Client side stripe API
    const hostUrl = getHostnameWithProtocol();
    console.log("HOST IS", hostUrl);
    const successUrl = hostUrl + "/success?sub_id=" + cognitoSub;
    const cancelUrl = hostUrl + "/cancel?sub_id=" + cognitoSub;
    const r = await getCognitoUser(cuname);
    const cognitoUser=r.user;
    const pausedSub = userSubscriptionRenewalPaused(cognitoUser);
    if (pausedSub) {
      const attMap = cognitoUserAttributesToMap (cognitoUser);
      setCancelAtEndOfPeriod(false,attMap[STRIPE_SUBSCR_ID]);
      const newuser = getCognitoUser(cuname);
      setCognitoUser(newuser);
      console.log("JUST UPDATED STATUS TO ACTIVE for ",cognitoUser);
    } else if (false){
      // Detect if user is for some reason alreay active... do nothing
      // Should never really get here though
    }
    else{
      const { error } = stripe.redirectToCheckout({
        customerEmail: authPayloadEmail,
        clientReferenceId: clientReferenceId,
        lineItems: [
          {
            price: "price_1OwMT8CDSeAskprci06mNV8d",
            quantity: 1,
          },
        ],
        mode: "subscription",

        //successUrl:"http://localhost:3000/webhook&session_id={CHECKOUT_SESSION_ID}",
        //cancelUrl: "http://localhost:3000",

        successUrl: successUrl + "&session_id={CHECKOUT_SESSION_ID}",
        cancelUrl: cancelUrl + "&session_id={CHECKOUT_SESSION_ID}",
      });
      console.log(error);
    }
    redirect("/");
  }

  return (
    <MenuItem
      sx={{ display: display }}
      onClick={handleClick}
      // this logic below is not correct for new accounts
      //disabled={NotEligibleToSubscribe()}
      variant="contained"
    >
      <Tooltip title="Activate your subscribtion">
        <SubscriptionsIcon />
      </Tooltip>
    </MenuItem>
  );
}
