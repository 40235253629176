import "../App.css";

import * as React from "react";
import { useContext } from "react";
import { View } from "@aws-amplify/ui-react";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { FilteredPeopleContext } from "../contexts/filteredpeoplecontext";
import getCharacteristicsImagePath from "../components/util/imageUtil";

var filteredPeopleCount = 0;
/**
 * The Generic Component
 * @param {String Representation of the enum typewe are trying to select}
 * @returns
 */
export default function SelectedCharacteristics({ myarr }) {
  const { filteredPeople } = useContext(FilteredPeopleContext);
  filteredPeopleCount = filteredPeople.length;
  if (myarr === undefined ) {
    filteredPeopleCount = 0;
    return "";
  }
  if (myarr.length === 0) {
    filteredPeopleCount = "";
  }
  

  return (
    <View>
      
      <Grid container spacing={1}>
        {Array.from(myarr).map(([key, value]) => {
          return (
            <CardMedia
              key={key}
              component="img"
              height="50"
              width="50"
              image={getCharacteristicsImagePath(key, value)}
              alt={key}
              sx={{ width: 30, maxHeight: 30, bgcolor: "white" }}
            />
          );
        })}
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 ,width:"25px"}}>{filteredPeopleCount}</Typography>
      </Grid>
      

    </View>
  );
}

// <Avatar alt="TEST" src={process.env.PUBLIC_URL + '/images/icons8-tick-60.png'} sx={{ width: 30, height: 30 }}/>
